.thumbnail {
  background-color: transparent !important;
  border-width: 0px;
  opacity: 0.9;
  width: 100%;
  /* color: #696868de;  sk */
  text-align: left;
  /*font-size:1vw;*/
}
.transparent-input:hover {
  background-color: transparent !important;
  border: none !important;
  width: 100%;
  color: rgb(204, 204, 204); /* sk */
}

.ReactModal__Overlay {
  -webkit-perspective: 600;
  perspective: 00;
  opacity: 0;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: rgba(133, 29, 29, 0.5);
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transition: opacity 150ms ease-out;
}

.ReactModal__Content {
  -webkit-transform: scale(0.5) rotateX(-30deg);
  transform: scale(0.5) rotateX(-30deg);
}

.ReactModal__Content--after-open {
  -webkit-transform: scale(1) rotateX(0deg);
  transform: scale(1) rotateX(0deg);
  transition: all 100ms ease-in;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content--before-close {
  -webkit-transform: scale(0.5) rotateX(30deg);
  transform: scale(0.5) rotateX(30deg);
  transition: all 150ms ease-in;
}

.ReactModal__Content.modal-dialog {
  border: none;
  background-color: transparent;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
