.azul{
  padding:4px;
  margin:10px;
  color:white;
  background-color:blue;
  -webkit-border-radius:5px;
  -o-border-radius:5px;
  -moz-border-radius:5px;
  -ms-border-radius:5px;
  -webkit-box-shadow:2px 2px 0px #1a71a9;
}
/* opacity:1;*/

.topnav{
  background-color: rgba(0,0,0,0);
  position:absolute;
  top:0;
  right:0;
  bottom:0;
  left:0;
  pointer-events:none;
}
/*
#xlsModal:target{
  opacity:1;
  opacity:0;
  pointer-events:auto;
}
*/

/* Naviero */
  /*overflow:hidden;*/
.xlsModalN{
  position:absolute;
  left:-300px;
  top:10;
  left:10;
  right:100;
  bottom:100;
  color:white;
  position:absolute;
  border-radius:5px;
}
.modal-contenidoN{
  background-color:black;
  width:600px;
  height:320px;
  padding:19px;
  pading:10px;
  opacity:1;
  /*margin: 0% auto;*/
  position:relative;
  z-index:100;
}

/* Terrestre */
.xlsModalT{
  position:absolute;
  left:-300px;
  top:10;
  left:10;
  right:100;
  bottom:100;
  /*overflow:hidden;*/
  color:white;
  position:absolute;
  border-radius:5px;
}
.modal-contenidoT{
  background-color:black;
  width:600px;
  height:320px;
  padding:19px;
  position:relative;
  opacity:1;
  z-index:100;
}
/* pading:10px;
margin: 0% auto;*/

/* SubCatalogos */
.xlsModalC{
  position:absolute;
  left:-300px;
  top:10;
  left:10;
  right:100;
  bottom:100;
  /*overflow:hidden;*/
  color:white;
  position:absolute;
  border-radius:5px;
}
.modal-contenidoC{
  background-color:black;
  width:600px;
  height:320px;
  padding:19px;
  pading:10px;
  /*margin: 0% auto;*/
  position:relative;
}
