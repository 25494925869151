.jumb {
  padding: 1rem 1rem;
  background-color: #eee;
  border-radius: 0.3rem;
  position: sticky;
  top: 0%;
  z-index: 1;
}
.icon {
  background-color: DodgerBlue;
  border-radius: 20%;
  color: white;
  padding: 6px 8px;
  font-size: 16px;
  cursor: pointer;
}
.icon:hover {
  background-color: RoyalBlue;
}

.red {
  background-color: red;
  color: white;
}
.green {
  background: green;
  color: white;
}
.purple {
  background: purple;
  color: white;
}
.headerSticky {
  color: black;
  background: white;
  position: sticky;
  top: 70px; /* Don't forget this, required for the stickiness */
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}

.divSticky {
  background: black;
  position: sticky;
  top: 0%;
}
