.cintillo-container {
  width: 100%;
  overflow: hidden;
  background-color: #072d6b; /* Color de fondo para mejor contraste */
  /*background-color: #000000; /* Color de fondo para mejor contraste */
  padding: 15px 0; /* Espacio vertical */
  border-bottom: 2px solid rgba(0, 0, 0, 0.2); /* Línea de sombra */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15); /* Sombra para profundidad */
}

.cintillo {
  display: inline-block;
  white-space: nowrap;
  animation: scroll linear infinite; /* La duración se ajustará desde React */
}

.cintillo-noticia {
  display: inline-block;
  padding: 0 100px; /* Espacio entre noticias */
  font-family: 'Montserrat', sans-serif; /* Fuente moderna */
  font-size: 22px; /* Tamaño del texto */
  color: #fff; /* Texto blanco para alto contraste */
  font-weight: 600; /* Negrita para visibilidad */
  text-transform: uppercase; /* Texto en mayúsculas */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3); /* Sombra para legibilidad */
  letter-spacing: 0.5px; /* Espaciado entre letras */
}

.cintillo-separador {
  display: inline-block;
  color: rgba(255, 255, 255, 0.5); /* Separadores en gris claro */
  font-size: 24px;
  margin: 0 20px; /* Espacio entre separadores */
}

/* Animación de scroll dinámico */
@keyframes scroll {
  0% {
    transform: translateX(0); 
  }
  100% { 
    transform: translateX(-50%); /* Se desplaza hasta la mitad para cubrir ambas copias */
  }
}