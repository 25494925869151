.container-900 {
    display: flex;
    flex-direction: row;
    height: 90vh;
  }
  .container-800 {
    display: flex;
    flex-direction: row;
    height: 80vh;
  }
  .container-700 {
    display: flex;
    flex-direction: row;
    height: 70vh;
  }
  .container-600 {
    display: flex;
    flex-direction: row;
    height: 60vh;
  }
  .container-500 {
    display: flex;
    flex-direction: row;
    height: 50vh;
  }
  .container-400 {
    display: flex;
    flex-direction: row;
    height: 40vh;
  }
  .container-200 {
    display: flex;
    flex-direction: row;
    height: 20vh;
  }
  .container-300 {
    display: flex;
    flex-direction: row;
    height: 30vh;
  }

  .left-col {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ddd;
  }
  
  .scroll-col {
    flex: 1;
    background: rgba(15, 15, 15, 0.40);
    border-width: 1px;
    border-style: solid;
    border-color: rgba(255, 255, 255, 0.10);
    overflow-y: scroll;
  }
  
  .center-col {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #e7e7e7;
  }

  .panel-resizable {
    border: 0px solid;
    padding: 20px; 
    margin:1em;
    width: 300px;
    resize: vertical;
    overflow: auto;
    /*scrollbar-width: auto; */
  }
  .panel-resizable-horizontal {
    border: 0px solid;
    padding: 20px; 
    margin:1em;
    width: 300px;
    resize: both;
    overflow: auto;
    /*scrollbar-width: auto; */
  }