body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.container{

  background-color: #FFFF;
}

.otd--col{
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
}

.otd-table{
  display: flex;
  justify-content: space-evenly;
  flex-direction: row-reverse;
  align-items: flex-start;
  margin: 2rem;
}


.otd-table .panel{
  display: flex;
  align-content: center;
  justify-content: flex-end;
  flex-direction: column;
  margin-top: 4rem;
  margin: 0 auto;
}

.otd-heigth{
  margin-bottom: 1rem;
}


.card{
  height: 100%;
}

.filter{
  display: flex;
  flex-direction: row-reverse;
}

.filter--form{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.filter--calendar{
  display: flex;
  justify-content: center;
}


.otd-container .pagination{

  display: flex;
  justify-content: center;
}

.otd-container .table{
    width: auto;
    border-collapse: collapse;
    margin: 0 auto;
    font-size: 0.9em;
    font-family: sans-serif;
    min-width: 400px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.otd-container .panel-resizable{
  margin: 0;
}

.otd-container .table thead tr {
  background-color: rgba(57, 121, 249, 0.3);
  color: #ffffff;
  text-align: left;
}

.otd-container .table th{
  color: #080808;
  text-align: center;
}


.otd-container .table th,
.otd-container .table td {
    padding: 12px 15px;
}

.otd-container .table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.otd-container .table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.otd-container .table tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}

.otd-container .table tbody tr.active-row {
  font-weight: bold;
}


.carbon--reset-alt {
  display: inline-block;
  width: 1em;
  height: 1em;
  --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath fill='%23000' d='M27 8H6.83l3.58-3.59L9 3L3 9l6 6l1.41-1.41L6.83 10H27v16H7v-7H5v7a2 2 0 0 0 2 2h20a2 2 0 0 0 2-2V10a2 2 0 0 0-2-2'/%3E%3C/svg%3E");
  background-color: currentColor;
  -webkit-mask-image: var(--svg);
  mask-image: var(--svg);
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
}

.loading-container{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #F8F8F8AD;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  z-index: 1000;
}


@media (min-width: 768px){
  .container.otd-container{
      max-width: 95%;
  }

}


@media ((min-width: 576px) and (max-width:768px)){
  .container.otd-container{
      max-width: 95%;
  }

  .row--filters{
    display: block;
  }

  .filter{
    flex-direction: column-reverse;
    align-items: center;
  }

  .row--filters .filter--form,
  .row--filters .filter--calendar{
    max-width: 100%;
  }

  .row--grafs{
    flex-direction: column;
    align-items: center;
  }

  .row--grafs .col-6{
    max-width: 75%;
  }

}

@media (min-width: 992px){
  .container.otd-container {
      max-width:  95%;
  }
}



body .unidades .comentarios.form-control {
	width:500px;
	overflow:hidden;
	background-color:#FFF;
	color:#222;
	font-family:Courier, monospace;
	font-weight:normal;
	font-size:20px;
	resize:none;
	line-height:40px;
	padding-left:100px;
	padding-right:100px;
	padding-top:45px;
	padding-bottom:34px;
	background-image:url(https://static.tumblr.com/maopbtg/E9Bmgtoht/lines.png), url(https://static.tumblr.com/maopbtg/nBUmgtogx/paper.png);
	background-repeat:repeat-y, repeat;
	-webkit-border-radius:12px;
	border-radius:12px;
	-webkit-box-shadow: 0px 2px 14px #000;
	box-shadow: 0px 2px 14px #000;
	border-top:1px solid #FFF;
	border-bottom:1px solid #FFF;
  height: 11vh;
  margin: 0 auto;
  width: 80%;
  padding: 0.7rem 0rem 0rem 6rem;

}

body .remolques label{
  font-size:20px;
  text-align: center;
}

body .remolques-container .alert{

	display: flex;
  justify-content: center;
  align-items: center;
}
.remolques-container .row {
  
  margin: 2rem 0 2rem 0;
}

.remolques-container .alert span{
	color: red;
}

.remolques-container .table-unidades{
  width: auto;
  border-collapse: collapse;
  margin: 0 auto;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.remolques-container .table-unidades th { 
	color: white;
    text-transform: uppercase;
    font-size: 0.875rem;
    font-weight: 400;
    background-color: #7595eb;
	text-align: center;
}

.remolques-container .table-unidades .columnas{
	text-align: -webkit-center;
	
}
.unidades .form-label{
  text-align: center;
}

.remolques-container .row .col-12{
  margin: 0 auto;
  padding: 0;
}

.remolques-container .row .head-table{
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: baseline;
}

.panel .panel-default .body{
  display: block;
}

.list-group-item.operador-flex{
  display: flex;
  align-items: center;
}

.list-group-item .select-operador{
  width: 80%;
  padding: 0 0 0 1rem;
}

.otd-container  .exportar #plantilla{
  background-color: #2da145;
  
}

.otd-container  .exportar #exportar{
  background-color: #2da145;
  padding: 6px;
}


.panel.panel-default{
  background: aliceblue;
}


.grid-table{
  background-color: #f3f3f3;
  margin: 0 auto;
}



.otd--col div{
  width: 100%;
}



.table-collapsed{
  margin-bottom: 5rem;
}

/* SEmaforo */
*{
	margin: 0;
	padding: 0;
}

.background{
  margin: 0 auto;
 
  zoom: 15%;
  width: 11rem;
  height: 150px;

}
#box-out{
	background-image: linear-gradient(right top, rgb(66,74,70) 15%, rgb(142,148,148) 58%);
	background-image: -o-linear-gradient(right top, rgb(66,74,70) 15%, rgb(142,148,148) 58%);
	background-image: -moz-linear-gradient(right top, rgb(66,74,70) 15%, rgb(142,148,148) 58%);
	background-image: -webkit-linear-gradient(right top, rgb(66,74,70) 15%, rgb(142,148,148) 58%);
	background-image: -ms-linear-gradient(right top, rgb(66,74,70) 15%, rgb(142,148,148) 58%);
	background-image: -webkit-gradient(linear,right top,left bottom,color-stop(0.15, rgb(66,74,70)),color-stop(0.58, rgb(142,148,148)));
	width:300px; height: 760px;
	border:solid 1px;
	border-radius: 10px;
	margin: 15px auto;
	padding: 20px 30px 20px 30px;
	transition:width 2s, height 2s;
	-webkit-transition:width 2s, height 2s, -webkit-transform 2s; /* Safari and Chrome */

}
/*#box-out:hover{
	width:300px; height: 760px;
	transform:rotate (360deg);
	-webkit-transform:rotate(360deg);
}*/

#box-inside{
	/*background: black;*/
	background: rgb(10,8,9);
	background: -moz-linear-gradient(-45deg, rgba(10,8,9,1) 0%, rgba(10,14,10,1) 71%, rgba(110,119,116,1) 74%, rgba(174,188,191,1) 77%, rgba(174,188,191,1) 100%);
	background: -webkit-gradient(linear, left top, right bottom, color-stop(0%,rgba(10,8,9,1)), color-stop(71%,rgba(10,14,10,1)), color-stop(74%,rgba(110,119,116,1)), color-stop(77%,rgba(174,188,191,1)), color-stop(100%,rgba(174,188,191,1)));
	background: -webkit-linear-gradient(-45deg, rgba(10,8,9,1) 0%,rgba(10,14,10,1) 71%,rgba(110,119,116,1) 74%,rgba(174,188,191,1) 77%,rgba(174,188,191,1) 100%);
	background: -o-linear-gradient(-45deg, rgba(10,8,9,1) 0%,rgba(10,14,10,1) 71%,rgba(110,119,116,1) 74%,rgba(174,188,191,1) 77%,rgba(174,188,191,1) 100%);
	background: -ms-linear-gradient(-45deg, rgba(10,8,9,1) 0%,rgba(10,14,10,1) 71%,rgba(110,119,116,1) 74%,rgba(174,188,191,1) 77%,rgba(174,188,191,1) 100%);
	background: linear-gradient(135deg, rgba(10,8,9,1) 0%,rgba(10,14,10,1) 71%,rgba(110,119,116,1) 74%,rgba(174,188,191,1) 77%,rgba(174,188,191,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0a0809', endColorstr='#aebcbf',GradientType=1 );
	border-radius: 10px;
	width:200px; height:670px;
	border: solid 1px;
	position:relative;
	padding: 20px 30px 20px 30px;
	/*margin: 15px auto;*/
	transition:width 2s, height 2s;
	-moz-transition:width 2s, height 2s, -moz-transform 2s; /* Firefox 4 */
	-webkit-transition:width 2s, height 2s, -webkit-transform 2s; /* Safari and Chrome */
	margin: 20px auto auto 20px;
	 
}/*
#box-inside:hover{
	width:200px; height:670px;
	transform:rotate (360deg);
	-webkit-transform:rotate(360deg);
}*/
.color1{
	/*background: #00CC33;*/
	background: rgb(118,217,65);
background: -moz-radial-gradient(center, ellipse cover, rgba(118,217,65,1) 0%, rgba(61,152,16,1) 50%, rgba(118,217,65,1) 100%);
background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%,rgba(118,217,65,1)), color-stop(50%,rgba(61,152,16,1)), color-stop(100%,rgba(118,217,65,1)));
background: -webkit-radial-gradient(center, ellipse cover, rgba(118,217,65,1) 0%,rgba(61,152,16,1) 50%,rgba(118,217,65,1) 100%);
background: -o-radial-gradient(center, ellipse cover, rgba(118,217,65,1) 0%,rgba(61,152,16,1) 50%,rgba(118,217,65,1) 100%);
background: -ms-radial-gradient(center, ellipse cover, rgba(118,217,65,1) 0%,rgba(61,152,16,1) 50%,rgba(118,217,65,1) 100%);
background: radial-gradient(ellipse at center, rgba(118,217,65,1) 0%,rgba(61,152,16,1) 50%,rgba(118,217,65,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#76d941', endColorstr='#76d941',GradientType=1 );
	}

#circulo1{
	
	border-radius: 200px;
	width:210px; height: 210px;
	position:relative;
	top: 0; left: 0;

/*Luz intenso (normal) */
}
#circulo1:hover{
	/*background-color: #66FF33;*/
	background: rgb(180,227,145); /* Old browsers */
background: -moz-radial-gradient(center, ellipse cover, rgba(180,227,145,1) 0%, rgba(97,196,25,1) 50%, rgba(180,227,145,1) 100%); /* FF3.6+ */
background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%,rgba(180,227,145,1)), color-stop(50%,rgba(97,196,25,1)), color-stop(100%,rgba(180,227,145,1))); /* Chrome,Safari4+ */
background: -webkit-radial-gradient(center, ellipse cover, rgba(180,227,145,1) 0%,rgba(97,196,25,1) 50%,rgba(180,227,145,1) 100%); /* Chrome10+,Safari5.1+ */
background: -o-radial-gradient(center, ellipse cover, rgba(180,227,145,1) 0%,rgba(97,196,25,1) 50%,rgba(180,227,145,1) 100%); /* Opera 12+ */
background: -ms-radial-gradient(center, ellipse cover, rgba(180,227,145,1) 0%,rgba(97,196,25,1) 50%,rgba(180,227,145,1) 100%); /* IE10+ */
background: radial-gradient(ellipse at center, rgba(180,227,145,1) 0%,rgba(97,196,25,1) 50%,rgba(180,227,145,1) 100%); /* W3C */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b4e391', endColorstr='#b4e391',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
	border-radius: 100px;
	width:210px; height: 210px;
	position:relative;
	top: 0; left: 0;
	
/*Luz mas intenso que el normal*/
}

.color2{
	/*background: #FFCC00;*/
	background: rgb(248,197,96);
background: -moz-radial-gradient(center, ellipse cover, rgba(248,197,96,1) 0%, rgba(247,189,53,1) 50%, rgba(234,152,10,1) 51%, rgba(238,154,10,1) 100%);
background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%,rgba(248,197,96,1)), color-stop(50%,rgba(247,189,53,1)), color-stop(51%,rgba(234,152,10,1)), color-stop(100%,rgba(238,154,10,1)));
background: -webkit-radial-gradient(center, ellipse cover, rgba(248,197,96,1) 0%,rgba(247,189,53,1) 50%,rgba(234,152,10,1) 51%,rgba(238,154,10,1) 100%);
background: -o-radial-gradient(center, ellipse cover, rgba(248,197,96,1) 0%,rgba(247,189,53,1) 50%,rgba(234,152,10,1) 51%,rgba(238,154,10,1) 100%);
background: -ms-radial-gradient(center, ellipse cover, rgba(248,197,96,1) 0%,rgba(247,189,53,1) 50%,rgba(234,152,10,1) 51%,rgba(238,154,10,1) 100%);
background: radial-gradient(ellipse at center, rgba(248,197,96,1) 0%,rgba(247,189,53,1) 50%,rgba(234,152,10,1) 51%,rgba(238,154,10,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f8c560', endColorstr='#ee9a0a',GradientType=1 );
}

#circulo2{
	margin-top: 20px;
	border-radius: 200px;
	width:210px; height: 210px;
	position:relative;
	top: 0; left: 0;
/*Luz intenso (normal) */
}
#circulo2:hover{

	/*background-color: #FFFF00;*/

	background: rgb(252,234,187); /* Old browsers */
background: -moz-radial-gradient(center, ellipse cover, rgba(252,234,187,1) 0%, rgba(252,205,77,1) 50%, rgba(248,181,0,1) 51%, rgba(251,223,147,1) 100%); /* FF3.6+ */
background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%,rgba(252,234,187,1)), color-stop(50%,rgba(252,205,77,1)), color-stop(51%,rgba(248,181,0,1)), color-stop(100%,rgba(251,223,147,1))); /* Chrome,Safari4+ */
background: -webkit-radial-gradient(center, ellipse cover, rgba(252,234,187,1) 0%,rgba(252,205,77,1) 50%,rgba(248,181,0,1) 51%,rgba(251,223,147,1) 100%); /* Chrome10+,Safari5.1+ */
background: -o-radial-gradient(center, ellipse cover, rgba(252,234,187,1) 0%,rgba(252,205,77,1) 50%,rgba(248,181,0,1) 51%,rgba(251,223,147,1) 100%); /* Opera 12+ */
background: -ms-radial-gradient(center, ellipse cover, rgba(252,234,187,1) 0%,rgba(252,205,77,1) 50%,rgba(248,181,0,1) 51%,rgba(251,223,147,1) 100%); /* IE10+ */
background: radial-gradient(ellipse at center, rgba(252,234,187,1) 0%,rgba(252,205,77,1) 50%,rgba(248,181,0,1) 51%,rgba(251,223,147,1) 100%); /* W3C */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fceabb', endColorstr='#fbdf93',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
	border-radius: 100px;
	width:210px; height: 210px;
	position:relative;
	top: 0; left: 0;
/*Luz mas intenso que el normal*/
}

.color3{
	/*ackground:red;*/
	background: rgb(216,82,82);
background: -moz-radial-gradient(center, ellipse cover, rgba(216,82,82,1) 0%, rgba(217,117,118,1) 32%, rgba(209,49,55,1) 49%, rgba(205,59,64,1) 71%, rgba(200,70,78,1) 100%);
background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%,rgba(216,82,82,1)), color-stop(32%,rgba(217,117,118,1)), color-stop(49%,rgba(209,49,55,1)), color-stop(71%,rgba(205,59,64,1)), color-stop(100%,rgba(200,70,78,1)));
background: -webkit-radial-gradient(center, ellipse cover, rgba(216,82,82,1) 0%,rgba(217,117,118,1) 32%,rgba(209,49,55,1) 49%,rgba(205,59,64,1) 71%,rgba(200,70,78,1) 100%);
background: -o-radial-gradient(center, ellipse cover, rgba(216,82,82,1) 0%,rgba(217,117,118,1) 32%,rgba(209,49,55,1) 49%,rgba(205,59,64,1) 71%,rgba(200,70,78,1) 100%);
background: -ms-radial-gradient(center, ellipse cover, rgba(216,82,82,1) 0%,rgba(217,117,118,1) 32%,rgba(209,49,55,1) 49%,rgba(205,59,64,1) 71%,rgba(200,70,78,1) 100%);
background: radial-gradient(ellipse at center, rgba(216,82,82,1) 0%,rgba(217,117,118,1) 32%,rgba(209,49,55,1) 49%,rgba(205,59,64,1) 71%,rgba(200,70,78,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d85252', endColorstr='#c8464e',GradientType=1 );
}

#circulo3{
	margin-top: 20px;
	border-radius: 200px;
	width:210px; height: 210px;
	position:relative;
	top: 0; left: 0;
/*Luz intenso (normal) */
}
#circulo3:hover{
	/*
	background-color: #FF3300;*/
	background: rgb(243,197,189); /* Old browsers */
background: -moz-radial-gradient(center, ellipse cover, rgba(243,197,189,1) 0%, rgba(232,108,87,1) 50%, rgba(234,40,3,1) 51%, rgba(255,102,0,1) 75%, rgba(199,34,0,1) 100%); /* FF3.6+ */
background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%,rgba(243,197,189,1)), color-stop(50%,rgba(232,108,87,1)), color-stop(51%,rgba(234,40,3,1)), color-stop(75%,rgba(255,102,0,1)), color-stop(100%,rgba(199,34,0,1))); /* Chrome,Safari4+ */
background: -webkit-radial-gradient(center, ellipse cover, rgba(243,197,189,1) 0%,rgba(232,108,87,1) 50%,rgba(234,40,3,1) 51%,rgba(255,102,0,1) 75%,rgba(199,34,0,1) 100%); /* Chrome10+,Safari5.1+ */
background: -o-radial-gradient(center, ellipse cover, rgba(243,197,189,1) 0%,rgba(232,108,87,1) 50%,rgba(234,40,3,1) 51%,rgba(255,102,0,1) 75%,rgba(199,34,0,1) 100%); /* Opera 12+ */
background: -ms-radial-gradient(center, ellipse cover, rgba(243,197,189,1) 0%,rgba(232,108,87,1) 50%,rgba(234,40,3,1) 51%,rgba(255,102,0,1) 75%,rgba(199,34,0,1) 100%); /* IE10+ */
background: radial-gradient(ellipse at center, rgba(243,197,189,1) 0%,rgba(232,108,87,1) 50%,rgba(234,40,3,1) 51%,rgba(255,102,0,1) 75%,rgba(199,34,0,1) 100%); /* W3C */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f3c5bd', endColorstr='#c72200',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
	border-radius: 100px;
	width:210px; height: 210px;
	position:relative;
	top: 0; left: 0;
/*Luz mas intenso que el normal*/
}

.row--grafs--panel{

  max-width: 50%;
  margin: 0 auto;
}
.erroresJson{
  background-color: rgb(252, 248, 253);
  overflow-y: scroll;
  border: 1px solid black;
  color: #000;
  max-height: 300px;
  padding: 2rem;
}

.modal-contenidoNJson{
  
  background: #fffffe;
    box-shadow: 0 3px 6px rgba(black, 0.16), 0 3px 6px rgba(black, 0.23);
    border-top: 10px solid #0B5AA2;
}


.xlsModalNJson{
    padding: 5rem;
    max-width: fit-content;
    margin-left: auto;
    margin-right: auto;
}
.remolques-container{
    padding: 20px;
    margin: 7% auto 5% auto;

}

.otdLive {

  font-family: Verdana,Geneva,sans-serif; 
}

.administracion{
  height: 450px;;
}

.css-dwuj3p-MuiTableCell-root {
  position: relative !important;
}

.administracion{
  height: 50vh;
  margin-bottom: 10%;
}

/* .notification.notification-success {
  position: fixed;
} */



 
