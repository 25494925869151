p#etiqueta a{
  text-align:center;
  color:white;
}
.plantilla {
  position:relative;
  top:0px;
  margin:0px;
  padding:0px;
  width:100%;
  height:37px;
  background-color:green;
  opacity:1;
  -webkit-border-radius:5px;
  -o-border-radius:5px;
  -moz-border-radius:5px;
  -ms-border-radius:5px;
  -webkit-box-shadow:2px 2px 0px #1a71a9;
}

.plantilla .tiptext {
    visibility:hidden;
    width: 120px;
    background-color:black;
    color: #fff;
    text-align: center;
    border-radius: 3px;
    padding: 6px 0;
    position: absolute;
    z-index: 1;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 1);
}
.plantilla:hover .tiptext {
    visibility:visible;
}

.exportar {
  position:relative;
  top:0px;
  margin:0px;
  padding:0px;
  width:100%;
  height:37px;
  background-color:blue;
  color: white;
  opacity:1;
  cursor:pointer;
  -webkit-border-radius:5px;
  -o-border-radius:5px;
  -moz-border-radius:5px;
  -ms-border-radius:5px;
  -webkit-box-shadow:2px 2px 0px #1a71a9;
}
.exportar .tiptext {
    visibility:hidden;
    width: 120px;
    background-color:black;
    color: #fff;
    text-align: center;
    border-radius: 3px;
    padding: 6px 0;
    position: absolute;
    z-index: 1;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
.exportar:hover .tiptext {
    visibility:visible;
}
.file {
  position:absolute;
  top:0px;
  left:0px;
  right:0px;
  bottom:0px;
  width:100%;
  height:100%;
  background-color:yellow;
}
  /*opacity:1;*/


/* Naviero */
.excelN p{
    cursor:pointer;
}
.excelN {
  position:relative;
  top:0px;
  margin:0px;
  padding:0px;
  width:100%;
  height:37px;
  background-color:blue;
  -webkit-border-radius:5px;
  -o-border-radius:5px;
  -moz-border-radius:5px;
  -ms-border-radius:5px;
  -webkit-box-shadow:2px 2px 0px #1a71a9;
}
/*   opacity:1;*/

/* Terrestre */
.excelT p{
    cursor:pointer;
}
.excelT {
  position:relative;
  top:0px;
  margin:0px;
  padding:0px;
  width:100%;
  height:37px;
  background-color:blue;
  -webkit-border-radius:5px;
  -o-border-radius:5px;
  -moz-border-radius:5px;
  -ms-border-radius:5px;
  -webkit-box-shadow:2px 2px 0px #1a71a9;
}
/*  opacity:1; */

/* SubCatálogos */
.excelC p{
    cursor:pointer;
}
.excelC {
  position:relative;
  top:0px;
  margin:0px;
  padding:0px;
  width:100%;
  height:37px;
  background-color:blue;
  -webkit-border-radius:5px;
  -o-border-radius:5px;
  -moz-border-radius:5px;
  -ms-border-radius:5px;
  -webkit-box-shadow:2px 2px 0px #1a71a9;
}
/*   opacity:1; */
